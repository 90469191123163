import React from 'react'
import classNames from 'classnames'
import { prepareHtml } from '../../../utils/text-to-html-formatter'
import DashedList from '../../../components/DashedList/DashedList'
import componentStyles from './CommonCases.module.scss'

const CommonCases = ({ customClasses, dashed, list, title }) => {
  const caseClasses = classNames(
    componentStyles.case,
    customClasses && customClasses.caseClass)
  const caseListClasses = classNames(
    componentStyles.caseList,
    customClasses && customClasses.caseListClass)
  const containerClasses = classNames(
    componentStyles.container,
    customClasses && customClasses.containerClass)
  const innerListClasses = classNames(
    componentStyles.list,
    customClasses && customClasses.listClass)
  const innerListItemClasses = classNames(
    componentStyles.listItem,
    customClasses && customClasses.listItemClass)

  const listElements = list.map(({ list, title }, index) => {
    const listEl = dashed ? (
      <DashedList customClasses={{
        listClass: innerListClasses,
        listItemClass: innerListItemClasses,
      }} list={list}/>) : (
      <ul className={innerListClasses}>
        {list.map((text, ind) => (
          <li className={innerListItemClasses} key={`${index}-${ind}`}>{text}</li>
        ))}
      </ul>
    )

    return (
      <div className={caseClasses} key={index}>
        <h4 className={componentStyles.listItemTitle}>{title}</h4>
        {listEl}
      </div>
    )
  })
  const preparedTitle = prepareHtml(title)

  return (
    <div className={containerClasses}>
      <h2 className={componentStyles.title} dangerouslySetInnerHTML={{ __html: preparedTitle }}/>
      <div className={caseListClasses}>
        {listElements}
      </div>
    </div>
  )
}

export default CommonCases
