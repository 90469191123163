import React from 'react'
import { getConfigByLanguage } from '../../../utils/common-utils'
import Layout from '../../../components/Layout/Layout'
import IndexContact from '../../index/IndexContact/IndexContact'
import ProductNavigation from '../../../components/ProductNavigation/ProductNavigation'
import YieldAdvantages from '../YieldAdvantages/YieldAdvantages'
import CommonTotal from '../../common/CommonTotal/CommonTotal'
import CommonPageTitle from '../../common/CommonPageTitle/CommonPageTitle'
import CommonCases from '../../common/CommonCases/CommonCases'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './YieldManagementPage.module.scss'

const YieldManagementPage = ({ language, location }) => {
  const config = getConfigByLanguage(language)
  const { cases, seo, title, total } = config.yieldManagement
  const productNavigationClasses = {
    productNavigationClass: componentStyles.productNavigation,
  }
  const listClass = componentStyles.list
  const listItemClass = componentStyles.listItem
  const additionValueClass = componentStyles.additionValue
  const additionDescriptionValueClass = componentStyles.additionDescriptionValue

  return (
    <Layout language={language} location={location} seo={seo}>
      <div className={componentStyles.yieldManagement}>
        <div className={layoutStyles.container}>
          <ProductNavigation customClasses={productNavigationClasses} />
          <CommonPageTitle
            description={title.description}
            title={title.title}
          />
          <YieldAdvantages
            customClasses={{
              additionDescriptionValueClass,
              additionValueClass,
              listClass,
              listItemClass,
            }}
          />
          <CommonCases
            customClasses={{
              caseClass: componentStyles.case,
              caseListClass: componentStyles.caseList,
              containerClass: componentStyles.caseContainer,
              listClass,
              listItemClass,
            }}
            list={cases.list}
            title={cases.title}
          />
        </div>
        <CommonTotal
          customClasses={{
            listItemClass: componentStyles.totalListItem,
          }}
          dashed={false}
          list={total.list}
          title={total.title}
        />
      </div>
    </Layout>
  )
}

export default YieldManagementPage
