import React from 'react'
import classNames from 'classnames'
import { prepareHtml } from '../../utils/text-to-html-formatter'
import componentStyles from './DashedList.module.scss'

const DashedList = ({ customClasses, list }) => {
  const listClasses = classNames(
    componentStyles.list,
    customClasses && customClasses.listClass)
  const listItemClasses = classNames(
    componentStyles.listItem,
    customClasses && customClasses.listItemClass)
  const listElements = list.map((item, index) => {
    const preparedText = prepareHtml(item)
    return (
      <li className={listItemClasses} dangerouslySetInnerHTML={{ __html: preparedText }} key={`item-${index}`}/>
    )
  })

  return (
    <ul className={listClasses}>
      {listElements}
    </ul>
  )
}

export default DashedList
