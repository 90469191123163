import React from 'react'
import classNames from 'classnames'
import DashedList from '../../../components/DashedList/DashedList'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './CommonTotal.module.scss'

const CommonTotal = ({ customClasses, dashed, list, title }) => {
  const listClasses = componentStyles.totalList
  const listItemClasses = classNames(
    customClasses && customClasses.listItemClass,
    componentStyles.totalListItem)
  const listItemTextClasses = classNames(
    customClasses && customClasses.listItemTextClass,
    componentStyles.totalListItemText)
  const listItemTitleClasses = classNames(
    customClasses && customClasses.listItemTitleClass,
    componentStyles.totalListItemTitle)
  const listElement = dashed ? (
    <DashedList customClasses={{
      listClass: listClasses,
      listItemClass: listItemClasses,
    }} list={list}/>
  ) : (
    <ul className={listClasses}>
      {list.map((item, index) => {
        const title = !item.title ? null : (
          <h4 className={listItemTitleClasses}>{item.title}</h4>
        )
        return (
          <li key={index} className={listItemClasses}>
            {title}
            <p className={listItemTextClasses}>{item.text}</p>
          </li>
        )
      })}
    </ul>
  )
  return (
    <div className={componentStyles.total}>
      <div className={layoutStyles.container}>
        <h2 className={componentStyles.totalTitle}>{title}</h2>
        {listElement}
      </div>
    </div>
  )
}

export default CommonTotal
