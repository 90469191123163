import React from 'react'
import { prepareHtml } from '../../../utils/text-to-html-formatter'
import Annotation from '../../../components/Annotation/Annotation'
import componentStyles from './CommonPageTitle.module.scss'

const CommonPageTitle = ({ annotation, description, title }) => {
  const descriptionHtml = prepareHtml(description)
  const titleHtml = prepareHtml(title)
  const annotationElement = annotation ? <Annotation className={componentStyles.annotation} text={annotation}/> : null

  return (
    <div className={componentStyles.titleContent}>
      <div className={componentStyles.titleBlock}>
        <h1 className={componentStyles.title} dangerouslySetInnerHTML={{ __html: titleHtml }}/>
        <p className={componentStyles.description} dangerouslySetInnerHTML={{ __html: descriptionHtml }}/>
      </div>
      {annotationElement}
    </div>
  )
}

export default CommonPageTitle
