import React from 'react'
import classNames from 'classnames'
import { getConfigByLanguage } from '../../utils/common-utils'
import { getCurrentLang } from '../../utils/current-language-service'
import { getCurrentLocation } from '../../utils/current-location-service'
import ShadowedContainer from '../ShadowedContainer/ShadowedContainer'
import Button from '../Button/Button'
import componentStyles from './ProductNavigation.module.scss'
import ForecastingIcon from '../../assets/images/Forecasting-icon.svg'
import MonitoringIcon from '../../assets/images/Monitoring-icon.svg'
import YieldManagementIcon from '../../assets/images/Yield-Management-icon.svg'

const iconsList = {
  ForecastingIcon,
  MonitoringIcon,
  YieldManagementIcon
}

const ProductNavigation = ({ children, customClasses }) => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const currentLocation = getCurrentLocation()
  const { products } = config

  const productNavigationClasses = classNames(
    componentStyles.productNavigation,
    customClasses && customClasses.productNavigationClass)
  const navigationClasses = classNames(
    componentStyles.navigation,
    customClasses.navigationClass)
  const listClasses = classNames(
    componentStyles.navigationList,
    customClasses.navigationListClass)
  const listItemClasses = classNames(
    componentStyles.navigationListItem,
    customClasses.navigationListItemClass)
  const listItemLinkContainerClasses = classNames(
    componentStyles.navigationLinkContainer,
    customClasses.navigationLinkContainerClass)
  const listItemLinkIconClasses = classNames(
    componentStyles.navigationIcon,
    customClasses.navigationIconClass)
  const listItemLinkTextClasses = classNames(
    componentStyles.navigationText,
    customClasses.navigationTextClass)

  return (
    <div className={productNavigationClasses}>
      <nav className={navigationClasses}>
        <ul className={listClasses}>
          {products.map(({text, iconComponent, url}, index) => {
            const Icon = iconComponent ? iconsList[iconComponent] : null
            const isCurrent = currentLocation && currentLocation.pathname && currentLocation.pathname.includes(url)
            const listItemLinkClasses = classNames(componentStyles.navigationLink, customClasses.navigationLinkClass, {
              [componentStyles.navigationLinkActive]: isCurrent
            })
            return (
              <li className={listItemClasses} key={`item-${index}`}>
                <ShadowedContainer className={listItemLinkContainerClasses}>
                  <Button className={listItemLinkClasses} href={url}>
                    <Icon className={listItemLinkIconClasses}/>
                    <span className={listItemLinkTextClasses}>{text}</span>
                  </Button>
                </ShadowedContainer>
              </li>
            )
          })}
          {children}
        </ul>
      </nav>
    </div>
  )
}

export default ProductNavigation
