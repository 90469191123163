import React from 'react'
import classNames from 'classnames'
import { prepareHtml } from '../../utils/text-to-html-formatter'
import componentStyles from './QuotedBlock.module.scss'

const QuotedBlock = ({ customClasses, text }) => {
  const containerClasses = classNames(
    componentStyles.container,
    customClasses && customClasses.containerClass)
  const textClasses = classNames(
    componentStyles.text,
    customClasses && customClasses.textClass)
  const preparedText = prepareHtml(text)

  return (
    <div className={containerClasses}>
      <p className={textClasses} dangerouslySetInnerHTML={{ __html: preparedText }}/>
    </div>
  )
}

export default QuotedBlock
