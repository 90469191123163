import React from 'react'
import classNames from 'classnames'
import { prepareHtml } from '../../../utils/text-to-html-formatter'
import { getCurrentLang } from '../../../utils/current-language-service'
import { getConfigByLanguage } from '../../../utils/common-utils'
import ValueLineDescription from '../../../components/ValueLineDescription/ValueLineDescription'
import ShadowedContainer from '../../../components/ShadowedContainer/ShadowedContainer'
import QuotedBlock from '../../../components/QuotedBlock/QuotedBlock'
import componentStyles from './YieldAdvantages.module.scss'
import Line1 from '../../../assets/images/Yield-Management-line-1.svg'
import Line2 from '../../../assets/images/Yield-Management-line-2.svg'

import YieldRevenueEn from '../../../assets/images/en/en_Yield_Management-img02.svg'
import YieldPackageEn from '../../../assets/images/en/en_Yield_Management-img01.svg'
import YieldCardsEn from '../../../assets/images/en/en_Yield_Management-img03.svg'

import YieldRevenueRu from '../../../assets/images/ru/ru_Yield_Management-img02.svg'
import YieldPackageRu from '../../../assets/images/ru/ru_Yield_Management-img01.svg'
import YieldCardsRu from '../../../assets/images/ru/ru_Yield_Management-img03.svg'

const paragraphsWithSmallMargin = [0, 1, 4, 5, 7]
const images = {
  en: {
    YieldRevenue: YieldRevenueEn,
    YieldPackage: YieldPackageEn,
    YieldCards: YieldCardsEn
  },
  ru: {
    YieldRevenue: YieldRevenueRu,
    YieldPackage: YieldPackageRu,
    YieldCards: YieldCardsRu
  }
}

const YieldAdvantages = ({ customClasses }) => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const { advantages } = config.yieldManagement
  const {
    YieldRevenue,
    YieldPackage,
    YieldCards
  } = images[language]
  const additionValueClass = customClasses && customClasses.additionValueClass
  const additionDescriptionValueClass = customClasses && customClasses.additionDescriptionValueClass

  const advantagesList = advantages.list.map(({ addition, text, title }, index) => {
    const itemClass = classNames(
      componentStyles.listItem,
      {
        [componentStyles.listItemSmallMargin]: paragraphsWithSmallMargin.indexOf(index) > -1,
      })
    const preparedText = prepareHtml(text)
    let additionElement = null
    if (addition) {
      additionElement = (
        <div className={componentStyles.listItemAddition}>
          <ValueLineDescription customClasses={{
            blockClass: componentStyles.listItemAdditionBlock,
            descriptionValueClass: additionDescriptionValueClass,
            valueClass: additionValueClass,
          }} value={addition.value} description={addition.description}/>
        </div>
      )
    }
    const titleElement = title ? (<h4 className={componentStyles.listItemTitle}>{title}</h4>) : null

    return (
      <div className={itemClass} key={index}>
        {titleElement}
        <div className={componentStyles.listItemContent}>
          <div className={componentStyles.listItemText}>
            <p dangerouslySetInnerHTML={{ __html: preparedText }}/>
          </div>
          {additionElement}
        </div>
      </div>
    )
  })

  const preparedDetail = advantages.package.detail.map((item, index) => {
    const preparedItem = prepareHtml(item)
    return (
      <p className={componentStyles.detailText} key={index} dangerouslySetInnerHTML={{ __html: preparedItem }}/>
    )
  })

  return (
    <div className={componentStyles.advantages}>
      {advantagesList[0]}
      {advantagesList[1]}
      {advantagesList[2]}
      <div className={componentStyles.detailRow}>
        <figure className={componentStyles.figure}>
          <ShadowedContainer className={componentStyles.shadowed}>
            <YieldPackage className={componentStyles.detailImg}/>
          </ShadowedContainer>
          <figcaption className={componentStyles.figureCaption}>{advantages.package.caption}</figcaption>
        </figure>
        <div className={componentStyles.detailContent}>
          {preparedDetail}
          <Line1 className={componentStyles.detailLine1}/>
          <Line2 className={componentStyles.detailLine2}/>
        </div>
      </div>
      <QuotedBlock customClasses={{
        containerClass: componentStyles.quoted,
      }} text={advantages.quoted}/>
      {advantagesList[3]}
      <figure className={componentStyles.figure}>
        <ShadowedContainer className={componentStyles.shadowed}>
          <YieldRevenue className={componentStyles.detailImg}/>
        </ShadowedContainer>
        <figcaption className={componentStyles.figureCaption}>{advantages.revenue.caption}</figcaption>
      </figure>
      {advantagesList[4]}
      {advantagesList[5]}
      {advantagesList[6]}
      {advantagesList[7]}
      {advantagesList[8]}
      <ShadowedContainer className={componentStyles.cards}>
        <YieldCards/>
      </ShadowedContainer>
    </div>
  )
}

export default YieldAdvantages
